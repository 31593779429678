<template>
  <div class="pbf grid_width">
 
    <GeneralSearchTags  
      :class="{gloading: isLoadingShortsData === 'pending'}"     
      :tags="props.intags" 
      :tagsselected="tagsselected" 
      :tagsdefault="props.intags" 
      :tagscount="shortsData?.items_tag"      
      :is-show-counts="false"
      @choose="chooseTags"
      @unchoose="unchooseTags"
      />

    <div class="sortb">
      <UiSort 
        @choose="chooseSort"
        :val="sort"    
        :options="[
          {id:'update', name: $t('universal_sort_last_update')},      
          {id:'score', name: $t('universal_sort_grow_score')},      
          {id:'likes', name: $t('universal_sort_likes2')},      
          {id:'create', name: $t('universal_sort_creation_date')},      
        ]"
        />
    </div>

      
    <SkExploreDiaries
      v-if="isLoadingShortsData === 'pending' && !shortsData?.items?.length"
      />
      
    <div v-else-if="shortsData?.items?.length" class="videolist">   
         
      <GeneralVideoShortLink 
        v-for="(opt, ind) in shortsData.items" 
        :key="ind"
        :cover="opt.item_photo.size_m"
        :name="opt?.title ??opt.item_diary?.name"
        :faza="opt.item_week?.faza"
        :week="opt.item_week?.days"
        :is-moderated="opt.is_moderated"
        :is-published="opt.is_published"
        :is-banned="opt.is_banned"
        :is-ready="opt.is_ready"
        :link="'/shorts/'+ opt.id"
        :video-id="opt.id"
        :author-id="opt.item_user.id"
        @click="click($event, ind)"
      />

      <ClientOnly>        
        <UiInfinityScroll 
          v-if="shortsData?.items?.length >= limit"
          :is-loading="isLoadingShortsData === 'pending'"
          ref="elInfinityScroll"/>
      </ClientOnly>
    </div>

    <Empty
      v-else-if="!shortsData?.items?.length"
      />

    <SkExploreDiaries
      v-else
      />
  

  </div>
</template>

<script setup lang="ts">
 
import { getTagsKeyword, getTagsKeys, getTagsSimple  } from '@/types/other'
import { useIntersectionObserver } from '@vueuse/core'
import { watchArray } from '@vueuse/core'


const start = ref(0)
const limit = ref(20)
const isEnded = ref(false)
const {$api, $ga} = useNuxtApp()
const elInfinityScroll = ref(null)
const sort = ref('update')
const tagsselected = ref([])
const tagspermanent = ref([]);
const isShowPlayer = ref(false)
const playerStartFrom = ref(0)

const props = defineProps({
  inurl: {
    type: String
  },
  intags: {
    type: Object
  },
  owner: {
    type: Boolean,
    default: false
  },
  brandId: {
    type: Number,
    required: false
  },
  productId: {
    type: Number,
    required: false
  },
  growerId: {
    type: Number,
    required: false
  },
  strainId: {
    type: Number,
    required: false
  }
});


if(props.brandId){
  tagspermanent.value.push('br:' + props.brandId);
}

if(props.productId){
  tagspermanent.value.push('pr:' + props.productId);
}

if(props.growerId){
  tagspermanent.value.push('us:' + props.growerId);
}

if(props.strainId){
  tagspermanent.value.push('st:' + props.strainId);
}


const clearPagination = function(){     
  start.value = 0;  
}

const chooseSort = function(selected_sort){   
  sort.value = selected_sort;    
  clearPagination();  
}
 

const unchooseTags = function(selected){  
  tagsselected.value = tagsselected.value.filter((item) => item != selected);
}        
const chooseTags = function(selected){          
  tagsselected.value.push(selected);      
  clearPagination();    
  $ga.eventGA('explore', 'tag', getTagsSimple(props.intags, tagsselected.value, tagspermanent.value));
}



const click = function(e, ind){
  playerStartFrom.value = ind
  showPlayer();
}

const setUrl = () => {
  if(import.meta.client)
    useRouteHistory().push('/shorts');
}
  
const showPlayer = function(){
  isShowPlayer.value = true;
  useTopbarHiddenMobile().value = true;
  useWarningMigrateHidden().value = true;
  useNavbarThemeRequired().value = 'dark';

  useModalsShortModalList().value = shortsData.value.items;
  useModalsShortModalListIndex().value = playerStartFrom.value;
  useModalsShortShow().value = true;
  useModalsShortHidePlayerFunc().value = hidePlayer;
  useModalsShortLoadMoreFunc().value = loadMore;
}

const hidePlayer = function(){
  isShowPlayer.value = false;
  useTopbarHiddenMobile().value = false;
  useWarningMigrateHidden().value = false;
  useNavbarThemeRequired().value = null;
  setUrl();
}

const loadMore = function(){
  if(isLoadingShortsData.value !== 'pending' && !isEnded.value)
    start.value = start.value + limit.value;
}

const loadData = async function() {  
  const response = await $api.getShorts({
    start: start.value,
    limit: limit.value,
    owner: props.owner ? 1 : 0,
    q: getTagsKeyword(props.intags, tagsselected.value, tagspermanent.value),       
    sortable: sort.value,
    tagsall: getTagsKeys(props.intags),
    tags: getTagsSimple(props.intags, tagsselected.value, tagspermanent.value)
  });
  return response;  
}


const { status: isLoadingShortsData,  data: shortsData } = await useLazyAsyncData('shorts_data_' + getTagsSimple(props.intags, tagsselected.value, tagspermanent.value), async () => await loadData())


watchArray([start, sort, tagsselected], async ([new_start, new_sort, new_tagsselected], added, removed) => {
  
  if(new_start === 0){
    shortsData.value.items = [];
    isEnded.value = false;
  }
  
  isLoadingShortsData.value = 'pending';
  var dt = await loadData();

    
  if(dt.length == 0)
    isEnded.value = true;  

  shortsData.value.items = [...shortsData.value.items, ...dt.items]

  if(useModalsShortShow().value)
    useModalsShortModalList().value = shortsData.value.items;

  if(new_start === 0){
    shortsData.value.items_tag = dt.items_tag
  }
  isLoadingShortsData.value = 'success';

  // $adv.apply();

}, {deep: true})



useIntersectionObserver(
  elInfinityScroll,
  ([{isIntersecting}]) => {
    if(isIntersecting){
      console.log('infinity scroll event');        
      if(isLoadingShortsData.value !== 'pending' && !isEnded.value)
        start.value = start.value + limit.value;
    }
  },
  { distance: 10 }
)


 
</script>

<style scoped>


.add_filter_box{
  display: flex;
  margin-left: auto;
  align-self: baseline;
}

.sortb{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}



.videolist {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: hidden;
  justify-content: left;
  gap: 1rem;
  transition: all 0.3s ease;  
}

@container pb (max-width: 560px) { 
  .videolist{
    justify-content: space-between;
  }

  .videolist:deep(.video_short){
    width: calc(100%/2 - 0.5rem);
  }
}


</style>
