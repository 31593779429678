<template>
  <div class="pbf" v-if="props.user">    


    <BrandTabShorts
      :owner="useAuth().getId() === props.user.id"
      :inurl="'/shorts'"
      :grower-id="props.user.id"      
      :intags="tags"
    />



    <!-- <ModalsShortEdit
      v-if="useModalsShortEditShow().value"
      :id="useModalsShortEditId().value"
    /> -->

    <ModalsShortEdit
      v-if="showEditor"
      :short-id="editableId"
      @close="showEditor = false"
    />
    

    
  </div>
</template>


<script setup lang="ts">

import { TagsShorts } from '@/types/other'
import type { User } from '@/types/user'

interface Props {
  user: User;
  id: number;
}

const route = useRoute();
const props = defineProps<Props>();
const showEditor = ref(route.query['eed'] ?? false);
const editableId = ref(route.query['eid'] ?? false);


const tags = ref(structuredClone(TagsShorts))

const router = useRouter();

// onMounted(() => {
//   if(showEditor.value && editableId.value){
//     useModalsShortEditShow().value = true
//   }
// })


watch(() => route.query['eed'], (value) => {
  showEditor.value = value
})

watch(() => route.query['eid'], (value) => {
  editableId.value = value
})



watch(router.currentRoute, (newCurrentRoute, oldCurrentRoute) => {
  
}, {deep: true})

</script>







<style scoped>
 

</style>
