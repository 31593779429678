<template>

  <div class="pbf editor" v-if="videoData">    


    <template v-if="!videoData.id">
      <!-- <h1 class="head">Uploading video</h1> -->
      <UiUploader      
        btn-name="Choose file"
        :enable-drag-drop="true"
        :show-button="false"
        :is-multiple="false"
        :limit="1"
        :types="['video/*']"
        :url-data="{type: 'short'}"
        :url="'/uploads/videos'"
        @changed="handleChangePhotos" 
        @uploaded="handleUploadedPhotos" 
        @progress="handleUploadingPhotos" 
        @errored="handleErroredPhotos"
      />
      
    </template>

    <div v-else-if="!useAuth().isOwner(videoData.item_user?.id)">
      Now allowed
    </div>



    
    <!-- <h1 class="head">{{videoData.title ?? 'New video'}}</h1> -->
    <ShortEditForm
      v-else-if="videoData.id"
      :video="videoData"
      @cancel="handleCancel"
      @save="handleSave"
      :is-saving="isLoadingSave"
    />







  </div>
</template>


<script setup lang="ts">

import { UploaderEndpointTypes } from '@/types/other'
import type { Video }  from "@/types/video";
import { createVideo }  from "@/types/video";
import type { User } from '@/types/user'

const { $popup, $api } = useNuxtApp();
const route = useRoute();


interface PageProps {
  shortId: number | null;
}

const props = defineProps<PageProps>()
const emits = defineEmits(['cancel'])

const isLoadingSave = ref(false)
const isLoadingFile = ref(false)
const loadingFileData = ref(null)

const handleChangePhotos = (files: File[]) => {
  console.log('Files changed:', files)
  isLoadingFile.value = true
}

const handleUploadedPhotos = async (response: any) => {
  videoData.value.id = response.id
  console.log('Upload completed:', response)

  setPathId(response.id)

  videoData.value = await loadData(response.id)
}

const handleUploadingPhotos = (progress: number) => {
  console.log('Upload progress:', progress)
  loadingFileData.value = progress
}

const handleErroredPhotos = (error: any) => {
  console.error('Upload error:', error)
}

const setPathId = (id: number) => {
  // useRouteHistory().push(`${props.user.link}/shorts/edit/${id}`)
}

const handleCancel = () => {
  // useRouter().push(`${props.user.link}/shorts`)
  emits('cancel')
}


const handleSave = () => {
  if (!validate()) return;
  save();
}

const validate = () => {
  if(!videoData.value.title){
    $popup.error('Title is required');
    return false;
  }
  return true;
}


const save = () => {            
  isLoadingSave.value = true;
  var form = {
    title: videoData.value.title,
    description: videoData.value.description,
    is_published: videoData.value.is_published,
    // item_photo: videoData.value.item_photo,
  }              

  $api.patchVideos(videoData.value.id, form) 
  .then((res) => {            
    $popup.success('Video saved');
    isLoadingSave.value = false;
    handleVideoPublished();
  })
  .catch(function (error) {     
    isLoadingSave.value = false;      
    $popup.error(error.message);     
  });

}

const handleVideoPublished = () => {  
  useRouter().push(`/shorts/${videoData.value.id}`)
}

const loadData = async function(id: number | null) {
  if (!id) {
    return createVideo();
  }
  return await $api.getVideo(id)
}

const { status: statusVideoData,  data: videoData } = await useLazyAsyncData('videoData' + props.shortId, async () => await loadData(props.shortId))


onMounted(() => {
  // useRouteHistory().push(useAuth().getLink() + '/shorts?eed=true&eid=' + props.id)
})

onUnmounted(() => {
  // useRouteHistory().push(useAuth().getLink() + '/shorts')
})

</script>







<style scoped>
 
.editor{
  height: 100%;
  display: flex;
  flex-direction: column;
}
.editor .head{
  padding: 1rem 2rem;
}
.editor:deep(.uploader){
  height: 100%;
}

.editor:deep(.drag-field){
  background-color: transparent;
  border: 0;
  height: 100%;
}


</style>
