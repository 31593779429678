<template>

<!-- {{ props.video }} -->



<div class="form">

  <div class="stepper">
    <UiStepper 
      v-model="currentStepId" 
      :show-check="false"
      :steps="[
        { id: 'info', label: 'Information' },
        // { id: 'cover', label: 'Cover' },
        { id: 'check', label: 'Moderation' },
        { id: 'access', label: 'Access' }
      ]"
    />
  </div>

  <!--  -->

  <div class="steps">

    <div class="info" v-if="currentStepId === 'info'">

      <div class="b__header">
        <h2>Video Information</h2>
      </div>

      <div class="b__info">

        <UiInput
          :label="'Title (Required)'"
          :text="video.title"
          :name="'title'"
          @change:text="video.title = $event"
        />

        <UiTextArea 
          :label="'Description'"
          :text="video.description"
          :rows="8"
          :max="500"
          :placeholder="'Enter description'"
          @change:text="video.description = $event"
        />


      </div>

      <div class="b__video">
        <div class="video-card">
          <iframe loading="lazy" :style="frameStyle" :src="'/video/' + video.id + '?header=false&controls=true'" frameborder="0" allowfullscreen></iframe>
          <div class="target">
            <div class="link-field">
              <div class="hint">Video link:</div>
              <a :href="videoLink" class="link" target="_blank">{{videoLink}}</a>
            </div>
            <i class="icon-copy copy" @click="copyLink"></i>
          </div>
        </div>
      </div>
 
    </div>





    <div class="cover" v-else-if="currentStepId === 'cover'">
      
      <ShortEditCoverSelector
        v-if="showFrameCoverSelector"
        :video-url="video.size_m"
        :video-cover-url="video.item_photo.size_m"
      />

      <div v-else class="cover-cur">
        <img loading="lazy" :src="video.item_photo.size_m" class="cover-img" alt="cover">
        <div class="cover-cur__actions">           
          <UiUploader      
            btn-name="Upload cover"
            :enable-drag-drop="false"
            :show-button="true"
            :is-multiple="false"
            :limit="1"
            :types="['image/*']"
            :url-data="{type: 'photo'}"
            :url="'/uploads/photos'"
            @changed="handleChangeCover" 
            @uploaded="handleUploadedCover" 
            @progress="handleUploadingCover" 
            @errored="handleErroredCover"
          />       
          <UiButton
            @click="showFrameCoverSelector = true"
            :name="'Select frame from video'"            
          />   
        </div>
      </div>

    </div>





    <div class="check" v-else-if="currentStepId === 'check'">
      <h2>Checking</h2>
      <div class="">
        We are checking your video. It will be available soon.
      </div>
    </div>






    <div class="access" v-else-if="currentStepId === 'access'">
      
      <div class="access">
        <div>Access</div>
        
        <label>
          <input
            type="radio"
            v-model="props.video.is_published"
            :value="true"
          />
          <div class="desc">
            <div class="nm">Published</div>
            <div class="hint">The video is available to all users</div>            
          </div>
        </label>

        <label>
          <input
            type="radio"
            v-model="props.video.is_published"
            :value="false"
          />
          <div class="desc">
            <div class="nm">Draft</div>
            <div class="hint">The video is not available</div>
          </div>
        </label>
        
      </div>

    </div>

  </div>



  <!--  -->


  <div class="actions">
    <template v-if="currentStepId === 'info'">
      <UiButton
        @click="currentStepId = 'check'"
        type="primary"
        :name="'Next'"
      />
    </template>
    <!-- <template v-else-if="currentStepId === 'cover'">
      <UiButton
        @click="currentStepId = 'check'"
        type="primary"
        :name="'Next'"
      />
    </template> -->
    <template v-else-if="currentStepId === 'check'">
      <UiButton
        @click="currentStepId = 'access'"
        type="primary"
        :name="'Next'"
      />
    </template>
    <template v-else-if="currentStepId === 'access'">
      <UiButton
        @click="handleSave"
        type="primary"
        :name="'Save'"
        :is-loading="isSaving"
      />
    </template>

    <!-- <UiButton
      @click="handleSave"
      type="primary"
      :name="'Save'"
      :is-loading="isSaving"
    /> -->

    <!-- <UiButton
      @click="handleCancel"
      :name="'Cancel'"
    /> -->
  </div>


  

</div>

</template>


<script setup lang="ts">
import { useClipboard } from '@vueuse/core'
import type { Video }  from "@/types/video";
import { ShortEditCoverSelector } from '#components';

interface PageProps {
  video: Video,
  isSaving: boolean
}

const props = withDefaults(defineProps<PageProps>(), {
  isSaving: false
})

const emits = defineEmits(['cancel', 'save'])

const { text: copyText, copy, copied: copyCopied, isSupported: copyIsSupported } = useClipboard()
const { $popup } = useNuxtApp();
const currentStepId = ref('info')
const showFrameCoverSelector = ref(false)

const frameStyle = computed(() => {
  return {};
})

const videoLink = computed(() => {
    return 'https://growdiaries.com/shorts/' + props.video.id
})


const copyLink = function(){
  copy(videoLink.value);
  $popup.success('Link copied');
}

const handleSave = () => {
  emits('save')
}

const handleCancel = () => {
  emits('cancel')
}



const handleChangeCover = (files: File[]) => {
  console.log('Files changed:', files)
}

const handleUploadedCover = async (response: any) => {
  console.log('Upload completed:', response)
  props.video.item_photo = response
}

const handleUploadingCover = (progress: number) => {
  console.log('Upload progress:', progress)
}

const handleErroredCover = (error: any) => {
  console.error('Upload error:', error)
}
 
</script>
   

<style scoped>


  .form{
    height: 600px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 80px 1fr 60px;
    grid-template-areas: 
      "stepper"
      "steps"
      "actions";
    gap: 1rem;
  }

  .stepper{
    margin: 0 auto;
    width: fit-content;    
    grid-area: stepper;
  }



  /* actions */

  .actions{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    padding: 1rem 2rem;
    grid-area: actions;
    border-top: 1px solid var(--un-background-color-gray);
  }



  /* steps */

  .steps{
    padding: 1rem 2rem;
    overflow-y: auto;
    grid-area: steps;
  }

  

  /* info */
  

  .info{
    display: grid;
    grid-template-columns: 1fr 35%;
    grid-gap: 1rem 2rem;;    
    /* border-radius: 20px; */
    grid-template-areas: 
      "b__header b__header"
      "b__info b__video";
    /* background-color: var(--un-background-color-gray); */
    /* max-width: 1000px;
    margin: 1rem auto; */
    margin-bottom: auto;
  }
  .b__header{
    grid-area: b__header;
  }
  .b__info{
    grid-area: b__info;
  }

  .b__video{
    grid-area: b__video;
  }
 


  /* cover */


  .cover-cur{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .cover-img{
    max-width: 500px;
    border-radius: 10px;
    max-height: 300px;
  }


  /*  */



  h2{
    margin-bottom: 1rem;
    font-size: 1.2rem;
  }


  .video-card{
    display: flex;
    flex-direction: column;
  }

  .video-card iframe{
    background-color: var(--un-background-color-gray-dark);
    border-radius:  10px 10px 0 0;
    aspect-ratio: 3 / 2;
  }

  .target{
    background-color: var(--un-background-color-gray-dark);
    border-radius: 0 0 10px  10px;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
  }

  .link-field{
    width: calc(100% - 60px);
  }
  .target .hint{
    font-size: 0.9rem;
    margin-bottom: 0.2rem;
  }

  .target .link{
    width: 100%;
    font-size: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    color: var(--un-firm);
  }
  .target .copy{
    font-size: 1.6rem;
    flex-shrink: 0;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: auto;
  }


.access{
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.access label{
  display: flex;
  gap: 1rem;
  align-items: center;
}

.access label input{
  margin: 0;
  zoom: 1.5;
}
.access label .desc{}
.access label .nm{}
.access label .hint{
  font-size: 0.8rem;
  color: var(--un-text-color-gray);
  display: block;
}
@container pb (max-width: 768px) {

  .form{
    grid-template-columns: 100%;
    grid-template-areas: 
      "header"
      "info"
      "video"
      "actions";
  }
   
  
}





  

</style>
