import type { Video } from './Video.interface'
// import type { User } from '../user'
// import type { Diary, Week } from '../diary'

export function createVideo(): Video {
  return {
    id: null,
    title: null,
    description: null,
    link: null,
    add_date: '',
    updated_date: '',
    published_date: '',
    type: null,
    config: null,
    is_published: false,
    size_m: null,
    height: null,
    width: null,
    duration: null,
    item_photo: {
      id: null,
      size_s: null,
      size_m: null,
    },
    item_diary: null,
    item_user: null,
    item_week: null,
  }
}
