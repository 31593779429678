<template>
  <div class="stepper-container" ref="containerRef">
    <div
      v-for="(step, index) in steps"
      :key="step.id"
      class="step-and-separator"
    >

      <div
        class="step"
        :class="{
          completed: index < currentIndex,
          active: index === currentIndex
        }"
        @click="onStepClick(step.id)"
        ref="el => stepRefs[index] = el"
      >
        <div class="step-badge">

          <svg
            v-if="index < currentIndex && showCheck"
            class="check-icon"
            viewBox="0 0 24 24"
            preserveAspectRatio="xMidYMid meet"
          >
            <path
              d="M9,19.4l-5.7-5.7l1.4-1.4L9,16.6L20.3,5.3l1.4,1.4L9,19.4z"
            />
          </svg>

          <span v-else>{{ index + 1 }}</span>
        </div>
        <div class="step-title">{{ step.label }}</div>
      </div>


      <div
        v-if="index < steps.length - 1"
        class="separator"
        :class="index < currentIndex ? 'filled' : ''"
      ></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, nextTick } from 'vue';

interface Step {
  id: string;
  label: string;
}

interface PageProps {
  steps: Step[];
  showCheck: boolean;
  modelValue: string; 
}

const props = withDefaults(defineProps<PageProps>(), {
  showCheck: true
});



const emit = defineEmits<{
   (event: 'update:modelValue', newValue: string): void;
}>();

const containerRef = ref<HTMLElement | null>(null);
const stepRefs = ref<(HTMLElement | null)[]>([]);

// Вычисляем индекс текущего шага (по id, переданному через modelValue)
const currentIndex = computed(() => {
  return props.steps.findIndex(step => step.id === props.modelValue);
});

// Обработчик клика для переключения шага
function onStepClick(stepId: string) {
  // Обновляем модель (v-model)
  emit('update:modelValue', stepId);
}

// При смене modelValue центрируем отображение активного шага
watch(
  () => props.modelValue,
  async () => {
    await nextTick();
    const idx = currentIndex.value;
    if (idx !== -1 && stepRefs.value[idx]) {
      stepRefs.value[idx]?.scrollIntoView({
        behavior: 'smooth',
        inline: 'center',
        block: 'nearest'
      });
    }
  }
);
</script>

<style scoped>
/* Контейнер всего степпера */
.stepper-container {
  display: inline-flex; /* Чтобы корректно работать с white-space и скроллом */
  align-items: center;
  background-color: #fff;
  padding: 16px 0;
  overflow-x: auto;
  white-space: nowrap; /* Позволяет прокручивать контент по горизонтали */
}

/* Обёртка для одной «пары»: шаг + разделитель */
.step-and-separator {
  display: flex;
  align-items: center;
  white-space: normal; /* Чтобы элементы внутри располагались корректно */
}

/* Базовые стили шага */
.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #999; /* Цвет неактивных шагов */
  min-width: 80px;
  cursor: pointer; /* Указываем, что на шаг можно кликать */
}

/* Активный шаг */
.step.active {
  color: #222; /* Более тёмный цвет */
}

/* Завершённый (пройденный) шаг */
.step.completed {
  color: #0c66f0; /* Выделяем завершённый шаг цветом */
}

/* «Медальон» с иконкой или номером шага */
.step-badge {
  width: 24px;
  height: 24px;
  border: 2px solid currentColor; /* Обводка в цвет шага */
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
}

/* Иконка «галочка» */
.check-icon {
  fill: currentColor;
  width: 20px;
  height: 20px;
}

/* Подпись шага */
.step-title {
  font-size: 14px;
  text-align: center;
  white-space: nowrap;
}

/* Разделительная линия между шагами */
.separator {
  width: 50px;
  height: 2px;
  background-color: #ddd; /* Нейтральный цвет разделителя */
  margin: 0 12px;
  transition: background-color 0.3s;
}

/* Заполненный (завершённый) разделитель */
.separator.filled {
  background-color: #0c66f0; /* Совпадает с цветом завершённого шага */
}
</style>