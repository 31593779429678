<template>



  <ClientOnly>
      <Teleport to="#app">
        
        <UiModal
          :width="'800px'"
          :is-scroll-content="true"
          :is-auto-height="true"
          :max-width="'800px'"
          @close="close"         
        >        

          <ShortEditEditor
            :title="modalTitle"
            :short-id="props.shortId"
            @cancel="close"
          />

        </UiModal>
      </Teleport>
  </ClientOnly>

</template>

<script setup lang="ts">


interface PageProps {
  shortId: number | null;
}

const props = defineProps<PageProps>()


const router = useRouter();
const modalTitle = ref('Video editor');

const emits = defineEmits(['close']);

const close = (closeWithPath: boolean = true) => {
  // if (oldUrl.value) {
    // useRouteHistory().push(oldUrl.value);
  // }

  // useModalsShortEditShow().value = false;
  // useRouteHistory().push(useAuth().getLink() + '/shorts')
  useRouter().push(useAuth().getLink() + '/shorts')
  emits('close');
}

onMounted(() => {
  // oldUrl.value = useRouteHistory().current();

  
})


onUnmounted(() => {
  // useModalsShortEditShow().value = false;
})


watch(router.currentRoute, (newCurrentRoute, oldCurrentRoute) => {
  // close(false)
}, {deep: true})

</script>


<style scoped>
 
.md{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 101;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.md__content{
  position: relative;
    width: 86%;
    height: 100vh;
    max-height: 620px;
    max-width: 1100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--un-background-color);
    margin: auto auto;
    border-radius: 20px;
    align-items: center;
    justify-content: center;
}

.md__close{
  position: absolute;
  top: 0;
  padding: 1rem;
  cursor: pointer;
  color: var('--un-text-color');
  font-size: 1.6rem;
  right: 0;
  aspect-ratio: 1 / 1;
  z-index: 1;
}

.md__body{  
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overscroll-behavior: contain;
  position: relative;
}

/* .md__body::before{
  content: "";
  display: block;
  height: 2rem;
  background: linear-gradient(to bottom, white, rgba(255, 255, 255, 0));
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
} */

.bk{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.8);
}


.pc{
  container: pc / inline-size;
}

</style>
